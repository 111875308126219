@font-face {
  font-family: 'Spoqa Han Sans Neo';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/SpoqaHanSansNeo-Regular.otf');
  src: local('Spoqa Han Sans Neo'), local('SpoqaHanSansNeo-Regular.otf'),
    url('../assets/fonts/SpoqaHanSansNeo-Regular.otf') format('opentype');
}
@font-face {
  font-family: 'Spoqa Han Sans Neo';
  font-style: normal;
  font-weight: 500;
  src: url('../assets/fonts/SpoqaHanSansNeo-Medium.otf');
  src: local('Spoqa Han Sans Neo'), local('SpoqaHanSansNeo-Medium.otf'),
    url('../assets/fonts/SpoqaHanSansNeo-Medium.otf') format('opentype');
}
@font-face {
  font-family: 'Spoqa Han Sans Neo';
  font-style: normal;
  font-weight: 700;
  src: url('../assets/fonts/SpoqaHanSansNeo-Bold.otf');
  src: local('Spoqa Han Sans Neo'), local('SpoqaHanSansNeo-Bold.otf'),
    url('../assets/fonts/SpoqaHanSansNeo-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'Pretendard Variable';
  font-weight: 45 920;
  font-style: normal;
  font-display: swap;
  src: local('Pretendard Variable'),
    url('../assets/fonts/PretendardVariable.woff2') format('woff2-variations');
}
@font-face {
  font-family: 'Pretendard';
  src: url('../assets/fonts/Pretendard-Thin.woff') format('woff');
  font-weight: 100;
  font-display: swap;
}
@font-face {
  font-family: 'Pretendard';
  src: url('../assets/fonts/Pretendard-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-display: swap;
}
@font-face {
  font-family: 'Pretendard';
  src: url('../assets/fonts/Pretendard-Light.woff') format('woff');
  font-weight: 300;
  font-display: swap;
}
@font-face {
  font-family: 'Pretendard';
  src: url('../assets/fonts/Pretendard-Regular.woff') format('woff');
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: 'Pretendard';
  src: url('../assets/fonts/Pretendard-Medium.woff') format('woff');
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: 'Pretendard';
  src: url('../assets/fonts/Pretendard-SemiBold.woff') format('woff');
  font-weight: 600;
  font-display: swap;
}
@font-face {
  font-family: 'Pretendard';
  src: url('../assets/fonts/Pretendard-Bold.woff') format('woff');
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: 'Pretendard';
  src: url('../assets/fonts/Pretendard-ExtraBold.woff') format('woff');
  font-weight: 800;
  font-display: swap;
}
@font-face {
  font-family: 'Pretendard';
  src: url('../assets/fonts/Pretendard-Black.woff') format('woff');
  font-weight: 900;
  font-display: swap;
}
